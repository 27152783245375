.contact{
    padding: 2rem 0;
}
 
/*-- Google Map --*/
.contact iframe{ 
    width: 100%;
    height: 40rem;
    margin-bottom: 2rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.contact-container{
    position: relative;
}

.contact-container::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 75%;
    width: 100%;
    background-color: var(--secondary-color);
}

.contact .box-container{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 0rem 5%;
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border: var(--border);
    padding: 4rem; 
    gap: 2rem;
}

/*-- Contact Information --*/
.contact-info{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}

.contact h3{
    font-size: 3rem;
    color: var(--secondary-color);
    font-weight: bold;
    margin-bottom: 2rem;
}

.contact-info .info-item{
    color: var(--secondary-color);
    padding-bottom: 2.5rem;
}

.contact-info .info-item .intro{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
}

.contact-info .info-item .icon{
    font-size: 1.8rem;
    color: var(--secondary-color);
}

.contact-info .info-item h4{
    font-size: 2.2rem;
    color: var(--secondary-color); 
}

.contact-info .info-item .content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
}

.contact-info .info-item p{
    font-size: 1.8rem;
    line-height: 1.5;
    color: var(--secondary-color);
    text-transform: none;
}

.contact-info .gmail{
    text-transform: none;
}

/*-- Contact Form --*/
.contact-form{    
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}

.contact-form .box{
    width: 100%;
    font-size: 1.6rem;
    color: var(--secondary-color);
    background-color: rgba(0, 0, 0, 0.05);
    text-transform: none;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
}

.contact-form .box::placeholder{
    text-transform: capitalize;
    color: var(--grey);
}

.contact-form textarea.box{
    height: 15rem;
    resize: none;
}

.contact-form button{
    background-color: transparent;
    margin-top: 1rem;
}

.contact-form .alert{
    font-size: 2rem;
    color: var(--main-color);
    padding-left: 1rem;
    padding-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contact .msg-alert{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 1rem;
}
