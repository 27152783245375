.home{
  padding: 0;
  height: 1px;
  min-height: 100vh;
  position: relative;
} 
 
.home-slider{
  position: relative; 
}  

.home-slider .swiper-button-next,
.home-slider .swiper-button-prev{
  background-color: var(--main-color);
  color: var(--white);
  font-size: 1rem; 
  height: 6rem;
  width: 4.5rem;
  padding: 2rem;
}

.home-slider .swiper-button-next{
  right: 0;
}

.home-slider .swiper-button-prev{
  left: 0;
}
  
.home-slider .swiper-button-next::after,
.home-slider .swiper-button-prev::after {
  font-size: 2rem;
  font-weight: bold;
  color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center; 
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
  
.home-slider .swiper-button-next:hover,
.home-slider .swiper-button-prev:hover {
  background-color: var(--secondary-color);
}
 
.home-item{
  min-height: 100vh;
}

.home-item img{
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.home-item .content{
  padding: 2rem 7%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
  background:         linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
}

.home-item .content .text{
  text-align: center;
  width: 80rem;
}

.home-item .content h3{
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.3;
  text-transform: capitalize;
  margin-bottom: 1rem;
  color: var(--white);
}

.home-item .content p{
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.15rem;
  color: var(--white);
  padding-bottom: 2rem;
  line-height: 1.6;
}