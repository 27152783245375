.about .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 3rem;
}

.about .image{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
    overflow: hidden;
}

.about .sub-image{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 15rem;
        flex: 1 1 15rem;
    height: 50rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1rem;
}

.about .sub-image.one img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.about .sub-image.two img{
    width: 100%;
    object-fit: cover;
}

.about .sub-image.two img:nth-child(1){
    height: 35%;
}

.about .sub-image.two img:nth-child(2){
    height: 65%;
}

.about .box-container .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}

.about .box-container .content h3{
    font-size: 3.5rem;
    color: var(--secondary-color);
    line-height: 1.3;
    padding-bottom: 2rem;
}

.about .box-container .content p{
    color: var(--grey);
    font-size: 1.6rem;
    line-height: 1.8;
    padding-bottom: 1rem;
}

.about .box-container .content .btn{
    margin-top: 2rem;
}
