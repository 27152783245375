.header .header-1,
.header .header-2{ 
    width: 100%;
    padding: 2rem 5%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-pack: justify; 
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1000;
    background: var(--white);
}

.header .header-contacts{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 3rem;
    list-style-type: none;
}

.header .header-contacts li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    gap: 1rem;
}

.header .header-contacts .icon{
    font-size: 1.6rem;
    color: var(--secondary-color);
}

.header .header-contacts h6{
    font-size: 1.7rem;
    color: var(--black);
}

.header .header-contacts span{
    text-transform: none;
    font-size: 1.6rem;
    color: var(--grey);
}

.header .action{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 3rem;
}

.header .icon-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1.5rem;
}

.header .icon-container .icon{
    font-size: 2rem;
    color: var(--black);
    cursor: pointer;
}

.header .icon-container .icon:hover{
    color: var(--main-color);
}

.header .header-2{
    background:var(--secondary-color);
    padding: 0rem 5%;
    height: 6rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header .header-2.active{
    position: fixed;
    top:0; 
    left: 0;
    -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
            box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

.header .navbar{
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    gap: 2.5rem;
}

.header .menu-btn{
    font-size: 2rem;
    color: var(--white);
    cursor: pointer;
    display: none;
}

.mobile-menu{
    display: none;
}


/*---------------- RESPONSIVE ----------------*/
@media (max-width: 991px){
    .header .menu-btn{
        display: block;
    } 

    .header .header-contacts{
        display: none;
    }

    .header .header-2{
        justify-content: flex-end;
    }
    
    .mobile-menu{
        display: block;
    }

    .header .navbar{
        display: none;
    }
}
























